<template>
  <div class="upto p20 bsbb">
    <van-nav-bar left-arrow @click-left="onClickLeft">
      <template #title>
        <div class="top-nav df aic jcsb">
          <div class="df aic jcc">
            <span style="font-weight: bold; font-size: 20px; line-height: normal">提现</span>
          </div>
        </div>
      </template>
    </van-nav-bar>
    <van-cell-group inset>
      <van-field v-model="value" label="提现金额" label-width="60px" placeholder="请输入提现金额" />
    </van-cell-group>
    <div class="text df fdc fz24 p20 bsbb">
      <p>提现规则:</p>
      <p>1.最低提现100元</p>
      <p>2.提现只能是整数,比如100 200 300 400</p>
      <p>3.提现时间 : 09:00-21:00</p>
    </div>
    <div class="df aic jcc">
      <van-button type="success" class="mt20" @click="show2 = true">提现</van-button>
    </div>
    <van-popup round class="p10 bsbb df fdc aic jcc" v-model:show="show2" style="width:90%">
      <p class="fz30 fw7 mb20">确认支付密码</p>
      <input class="fz20"
        style="width:90%;height:40px;background: #ededed;border:none;text-indent: 10px;line-height:40px" type="password"
        placeholder="请输入您的支付密码" v-model="payPassword">
      <div class="btns df aic jcsb mt20" style="width:80%">
        <button class="fz18" style="width:120px;height:40px;border:none;" @click="show2 = false">取消</button>
        <button class="fz18" style="width:120px;height:40px;border:none;" @click="confirmPa">确认</button>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Toast } from "vant";
import { withdraw,checkPayPwdIsCorrect } from '@/utils/api.js'
export default {
  data() {
    return {
      value: "",
      show2: false,
    };
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    submit() {
      if (this.value % 100 !== 0) {
        Toast.fail("请输入整数金额");
      } else {
        Toast.success("提现成功");
        setTimeout(() => {
          this.$router.go(-1);
        }, 1500);
      }
    },
    confirmPa() {
      checkPayPwdIsCorrect({ payPassword: this.payPassword }).then(res => {
        if (res.code == 200) {
          this.onsubmit()
          setTimeout(() => {
            this.show2 = false
          }, 100)
        }
      })
    },
    onsubmit() {
      if (this.value % 100 !== 0) {
        this.$toast('请输入整数金额')
        return false
      }
      const param = { money: this.value }
      withdraw(param).then(res => {
        if (res.code === 200) {
          this.$dialog.alert({
            message: res.message,
            theme: 'round-button',
            confirmButtonColor: 'green'
          }).then(() => {
            this.onClickLeft()
          })
        } else {
          this.$toast(res.message)
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .van-badge__wrapper {
  font-size: 30px !important;
}

::v-deep .van-nav-bar__arrow {
  font-size: 46px !important;
}

::v-deep .van-nav-bar__content {
  height: 70px;
  line-height: 70px;
}

.van-dropdown-menu__bar {
  box-shadow: none !important;
}

.van-nav-bar__content {
  box-shadow: none !important;
}

.upto {
  .van-cell-group {
    margin-top: 20px;
    height: 160px;

    .van-field {
      height: 160px;
      line-height: 120px;
    }
  }

  .text {
    >p {
      margin-bottom: 10px;
    }
  }

  .van-button {
    background: #26bd68;
    color: #fff;
    width: 690px;
    height: 100px;
    border-radius: 50px;
  }
}

.van-popup {
  .van-button {
    background: #26bd68;
    color: #fff;
  }

}
</style>
